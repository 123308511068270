export enum UserRole {
    customer_company_admin = 'customer_company_admin',
    customer_company_user = 'customer_company_user',
    user = 'user',
    anonymous_user = 'anonymous_user',
}

export enum SocialMedia {
    facebook = 'facebook',
    twitter = 'twitter',
}

export enum Countries {
    UNITED_STATES = 'united-states',
    CANADA = 'canada',
    MEXICO = 'mexico',
    ARGENTINA = 'argentina'
}

export enum CountryCallingCode {
    UNITED_STATES = '+1',
    CANADA = '+1',
    MEXICO = '+52',
    ARGENTINA = '+54'
}

export enum CountryCodes {
    UNITED_STATES = 'US',
    CANADA = 'CA',
    MEXICO = 'MX',
    ARGENTINA = 'AR',
}

export enum ReviewStatus {
    Approved = 'approved',
    Rejected = 'rejected',
    Hidden = 'hidden',
    Reviewing = 'reviewing',
    Internal = 'internal'
}

export type ReviewStatusType =
    | ReviewStatus.Approved
    | ReviewStatus.Rejected
    | ReviewStatus.Hidden
    | ReviewStatus.Reviewing
    | ReviewStatus.Internal;

export interface ICarrier {
    id: string
    dot_number: string
    name: string
    status_code: string
    carrier_id?: string
    carrier_dot_number?: string
    carrier_name?: string
    carrier_dba_name?: string
    contacts: Partial<IShipperContact>[]
}

export interface IShipperContact {
    shipper_carrier_id: string
    user_id: string
    full_name: string
    first_name: string
    last_name: string
    email_address: string
    mobile_number_country_code: string
    mobile_number: string
    status_code: 'active' | 'inactive' | 'deleted'
    id: string
}

export const countryCodesUniversal = {
    [Countries.UNITED_STATES]: CountryCodes.UNITED_STATES,
    [Countries.MEXICO]: CountryCodes.MEXICO,
    [Countries.CANADA]: CountryCodes.CANADA,
    [Countries.ARGENTINA]: CountryCodes.ARGENTINA
};

export const countryCallingCodes = {
    [Countries.UNITED_STATES]: CountryCallingCode.UNITED_STATES,
    [Countries.CANADA]: CountryCallingCode.CANADA,
    [Countries.MEXICO]: CountryCallingCode.MEXICO,
    [Countries.ARGENTINA]: CountryCallingCode.ARGENTINA
};

export interface IFacilityReview {
    id: string
    d411_id_d411_id_id: string
    d411_id_d411_id: string
    d411_id_name: string
    d411_id_city: string
    d411_id_state_province: string
    d411_id_location_type_code: string
    d411_id_lat: string
    d411_id_lng: string
    d411_id_driver_rating: string
    d411_id_driver_rating_last_30_days: string
    d411_id_driver_rating_up_to_30_days_ago: string
    overall_rating: string
    additional_comment: string
    private_comment: string
    company_response: string
    status_code: string
    created_at: string
    created_at_in_users_tz: any
    created_by_user_first_name: string
    created_by_user_last_name: string
    created_by_user_handle: string
    created_by_user_points_total: string
    created_by_user_average_rating: any
    created_by_user_profile_picture: any
    created_by_user_profile_picture_full_url: string
}

export interface IReview {
    id: string
    cleanliness_rating: string
    cleanliness_comment: string
    lighting_rating: string
    lighting_comment: string
    lounge_rating: string
    lounge_comment: string
    maneuverability_rating: string
    maneuverability_comment: string
    parking_rating: string
    parking_comment: string
    professionalism_rating: string
    professionalism_comment: string
    restrooms_rating: string
    restrooms_comment: string
    safety_rating: string
    safety_comment: string
    security_rating: string
    security_comment: string
    times_rating: string
    times_comment: string
    additional_comment: string
    company_response: any
    overall_rating: string
    wait_time: string
    load_time: string
    unload_time: string
    dot_number: any
    mc_number: any
    carrier_name: any
    helpful_score: string
    d411_id_request_id: any
    d411_id_id: string
    d411_id_d411_id: string
    d411_id_location_type_code: string
    d411_id_name: string
    d411_id_company_name: string
    d411_id_lat: string
    d411_id_lng: string
    d411_id_time_zone_code: string
    d411_id_driver_rating: string
    d411_id_driver_rating_last_30_days: string
    d411_id_driver_rating_up_to_30_days_ago: string
    created_by_user_first_name: string
    created_by_user_last_name: string
    created_by_user_handle: string
    created_by_user_points_total: string
    created_by_user_average_rating: string
    created_by_user_profile_picture: string
    created_by_user_profile_picture_full_url: string
    updated_by_user_first_name: any
    updated_by_user_last_name: any
    updated_by_user_handle: any
    updated_by_user_points_total: any
    updated_by_user_average_rating: any
    updated_by_user_profile_picture: any
    updated_by_user_profile_picture_full_url: string
    helpful_reviews_is_helpful: 'Y' | 'N' | 'U',
    created_at: string
    status_code: 'new' | 'approved' | 'rejected' | 'hidden' | 'internal' | 'reviewing',
    private_comment: string
    is_hidden: 'Y' | 'N'
    is_hidden_until: string
    media: {
        photos: IPhoto[],
        files: string[],
        videos: string[]
    }
}

export enum InputTypes {
    INPUT = 'input',
    TEXTAREA = 'textarea',
    PHONE = 'phone'
}

export enum Flags {
    need_name = 'need_name',
    need_phone = 'need_phone',
    need_email = 'need_email',
    need_handle = 'need_handle',
    need_dot = 'need_dot',
    payment_required = 'payment_required',
}

export type Flag = Flags.need_email | Flags.need_handle | Flags.need_dot | Flags.need_phone | Flags.payment_required | Flags.need_name;

export enum OfferStatus {
    ready = 'ready',
    draft = 'draft',
    expired = 'expired',
}

export enum ErrorCodes {
    InternalServer = 500,
    WrongSignUpBrowser = 'WrongSignUpBrowser',
    VerificationCodeNotValid = 'VerificationCodeNotValid',
    ReviewAlreadyHidden = 'ReviewAlreadyHidden',
}

export type Roles = UserRole.customer_company_admin | UserRole.customer_company_user | UserRole.user | UserRole.anonymous_user;
export interface IFavorite {
    user_id: string;
    d411_id_id: string;
    d411_id_d411_id: string;
    d411_id_location_type_code: string;
    d411_id_name: string;
    d411_id_company_name: string;
    d411_id_lat: string;
    d411_id_lng: string;
    d411_id_time_zone_code: string;
    d411_id_driver_rating: string;
    d411_id_driver_rating_last_30_days: string;
    d411_id_driver_rating_up_to_30_days_ago: string;
    poster_image_url: string;
    approved_company_name_id?: any;
    approved_company_name_name?: any;
    approved_company_name_status_code?: any;
    company_name_to_use: string;
    address_id: string;
    address_address1: string;
    address_address2: string;
    address_city: string;
    address_state_province_code: string;
    address_postal_code: string;
    address_country_code: string;
    address_full_address: string;
    address_lat: string;
    address_lng: string;
    address_state_province_name: string;
    address_state_province_abbreviation: string;
    address_country_name: string;
    address_country_abbreviation: string;
    address_country_three_letter_abbreviation: string;
    address_country_calling_code: string;
}
export interface IQuote {
    id: string
    object: string
    configuration: string
    created: number
    customer: string
    flow: any
    livemode: boolean
    locale: any
    on_behalf_of: any
    return_url: string
    url: string
}

export interface IUserJWT {
    iss: string;
    iat: number;
    exp: number;
    aud: string;
    sub: string;
    sid: string;
    eml: string;
    fnm: string;
    lnm: string;
    hnd: string;
    gnd: string;
    lng: string;
    loc: string;
    tzn: string;
    uom: string;
    rol: Roles[];
    flg: Flag[];
    cid: string;
    avt: string;
};

export interface IProfile {
    id: string
    d411_id: string
    location_type_code: string
    name: string
    company_name: string
    manager_name: any
    contact: any
    lat: string
    lng: string
    time_zone_code: string
    driver_rating: string
    driver_rating_last_30_days: string
    driver_rating_up_to_30_days_ago: string
    d411_rating: any
    views: string
    address1: string
    city: string
    state_province_code: string
    postal_code: string
    state_province: string
    state_province_abbreviation: string
}


export interface IOffer {
    id: string;
    body: string;
    customer_company_id: string;
    recipient_name: string;
    number_of_profiles: string;
    number_of_profiles_used: string;
    price: string;
    preferred_payment_period_code: string;
    is_autorenew: string;
    stripe_product_id: string;
    stripe_payment_intent_id: string;
    to_email_address: string;
    subject: string;
    expires_at: string;
    status_code: string;
    customer_company_name: string;
    customer_company_stripe_id: string;
    stripe_quote_id: string;
}

export interface IInvitableUsers {
    id: string;
    handle: string;
    first_name: string;
    last_name: string;
    email_address: string;
    profile_picture: string;
    status_code: keyof StatusesCodes;
    name: string;
}
export interface StatusesCodes {
    preregistered: 'Invited',
    new: 'Invited',
    confirmed: 'Ready',
    active: 'Ready',
    suspended: 'Suspended',
    banned: 'Banned',
    deleted: 'Deleted',
}
export interface IMyUsers {
    id: string;
    handle: string;
    first_name: string;
    last_name: string;
    email_address: string;
    is_customer_company_admin: string;
    profile_picture: string;
    status_code: keyof StatusesCodes;
    is_joined_to_company: string;
    accessible_profiles: string;
    d411_id?: string;
}

export interface IUser {
    id: string;
    code: string;
    insert_sequence: string;
    aws_cognito_key?: any;
    facebook_id?: any;
    customer_company_id: string;
    is_customer_company_admin: string;
    first_name: string;
    last_name: string;
    handle: string;
    password: string;
    email_address: string;
    city?: string;
    region?: string;
    country?: string;
    postal_code?: string;
    phone_country_code: Countries;
    phone: string;
    phone_number_as_numbers: string;
    profile_picture?: string;
    bio: string;
    dob?: string;
    year_started_driving?: string;
    points_remaining: string;
    points_total: string;
    header_photos?: string;
    company_name?: string;
    usdot_num: string;
    mc_num?: string;
    event_code?: string;
    rig?: string;
    gender: string;
    last_logged_in_at: string;
    login_count: string;
    registered_at: string;
    confirmed_at: string;
    status_code: string;
    created_at: string;
    created_by_user_id: string;
    user_lat?: string;
    user_lng?: string;
    user_geohash?: string;
    average_rating?: string;
    language_code: string;
    time_zone_code?: string;
    locale_code: string;
    units_of_measure_code: string;
    source_customer_company_id?: string;
    source_integrated_product_id?: string;
    source_reference_id?: string;
    customer_company_insert_sequence: string;
    customer_company_common_id: string;
    customer_company_name: string;
    customer_company_address1: string;
    customer_company_address2: string;
    customer_company_city: string;
    customer_company_state_province_code: string;
    customer_company_postal_code: string;
    customer_company_country_code?: string;
    customer_company_main_phone_country_code: string;
    customer_company_main_phone: string;
    customer_company_alt_phone_country_code: string;
    customer_company_alt_phone: string;
    customer_company_fax_phone_country_code: string;
    customer_company_fax_phone: string;
    customer_company_web_address: string;
    customer_company_default_domain?: string;
    customer_company_lat: string;
    customer_company_lng: string;
    customer_company_geohash: string;
    customer_company_original_lat: string;
    customer_company_original_lng: string;
    customer_company_original_geohash: string;
    customer_company_geocoded_lat: string;
    customer_company_geocoded_lng: string;
    customer_company_geocoded_geohash: string;
    customer_company_description?: string;
    customer_company_api_key: string;
    customer_company_aws_id: string;
    customer_company_stripe_id?: string;
    customer_company_subscription_plan_code: string;
    customer_company_subscription_starts_at: string;
    customer_company_subscription_ends_at: string;
    customer_company_billing_address1?: string;
    customer_company_billing_address2?: string;
    customer_company_billing_city?: string;
    customer_company_billing_state_province_code?: string;
    customer_company_billing_postal_code?: string;
    customer_company_billing_country_code?: string;
    customer_company_billing_email_address?: string;
    customer_company_billing_method_code?: string;
    customer_company_billing_credit_card_type_code?: string;
    customer_company_billing_credit_card_number?: string;
    customer_company_billing_credit_card_expiration?: string;
    customer_company_billing_credit_card_cvv?: string;
    customer_company_billing_credit_card_name?: string;
    customer_company_status_code: string;
    customer_company_created_at: string;
    customer_company_created_by_user_id: string;
    customer_company_updated_at: string;
    customer_company_updated_by_user_id: string;
    customer_company_research_complete_notification_method_code?: string;
    customer_company_research_complete_notification_email_address?: string;
    customer_company_research_complete_notification_url?: string;
    customer_company_zoominfo_company_id?: string;
    state_province_code?: string;
    state_province_insert_sequence?: string;
    state_province_name?: string;
    state_province_abbreviation?: string;
    state_province_country_code?: string;
    country_code?: string;
    country_insert_sequence?: string;
    country_name?: string;
    country_abbreviation?: string;
    country_three_letter_abbreviation?: string;
    country_calling_code?: string;
    country_status_code?: string;
    phone_country_insert_sequence: string;
    phone_country_name: string;
    phone_country_abbreviation: string;
    phone_country_three_letter_abbreviation: string;
    phone_country_calling_code: CountryCallingCode;
    phone_country_status_code: string;
    created_by_user_first_name: string;
    created_by_user_last_name: string;
    created_by_user_handle: string;
    created_by_user_city?: string;
    created_by_user_region?: string;
    created_by_user_points_total: string;
    created_by_user_profile_picture?: string;
    created_by_user_profile_picture_full_url: string;
    created_by_user_average_rating?: string;
    updated_by_user_first_name: string;
    updated_by_user_last_name: string;
    updated_by_user_handle: string;
    updated_by_user_city?: string;
    updated_by_user_region?: string;
    updated_by_user_points_total: string;
    updated_by_user_profile_picture?: string;
    updated_by_user_profile_picture_full_url: string;
    updated_by_user_average_rating?: string;
    language_name: string;
    language_status_code: string;
    language_is_default: string;
    time_zone_name?: string;
    time_zone_common_name?: string;
    time_zone_status_code?: string;
    time_zone_is_default?: string;
    profile_picture_full_url: string;
}

export interface IProfileAccess {
    id: string;
    insert_sequence: string;
    d411_id: string;
    location_type_code: string;
    customer_company_id: string;
    name?: any;
    company_name?: any;
    address_id: string;
    address_shipper_uses: string;
    lat: string;
    lng: string;
    time_zone_code?: any;
    driver_rating: string;
    driver_rating_last_30_days: string;
    driver_rating_up_to_30_days_ago?: any;
    d411_rating?: any;
    status_code: string;
    views: string;
    location_type_name: string;
    approved_company_name_id?: any;
    approved_company_name_name?: any;
    approved_company_name_status_code?: any;
    company_name_to_use?: any;
    address_address1: string;
    address_address2: string;
    address_city: string;
    address_state_province_code: string;
    address_postal_code: string;
    address_country_code: string;
    address_full_address: string;
    address_lat: string;
    address_lng: string;
    address_state_province_name: string;
    address_state_province_abbreviation: string;
    address_country_name: string;
    address_country_abbreviation: string;
    address_country_three_letter_abbreviation: string;
    address_country_calling_code: string;
    time_zone_name?: any;
    time_zone_current_time?: any;
    time_zone_now: string;
    access_role: string;
}

export interface IFacilityProfile {
    d411_id: D411id;
    details: Details;
    media: Media;
    reviews_summary: Reviewssummary;
    redirect_here: any[];
    questions_summary: Questionssummary;
    business: Business;
    user: User;
    consuming: Consuming;
    lists: IFacilityProfileLists[]
}

export interface IFacilityProfileLists {
    id: string;
    d411_id_is_on_list: 'Y' | 'N';
    list_type_code: 'personal' | 'company';
    name: string;
    d411_id_count: string;
    cover_color_custom_value: string;
}

interface Consuming {
    time: string;
    memory: string;
}

export interface IUserFlags {
    is_favorite: string;
    may_submit_question: string;
}

interface User {
    flags: IUserFlags;
    subscriptions: {
        email_address_status_code: string;
        mobile_number_status_code: string;
    };
    permissions: {
        edit: string;
        review: string;
    };
}
interface Business {
    subscription_type_code: string;
    subscription_status_code: string;
    subscription_expires_at: string;
    subscription_auto_renews: string;
    is_managed: string;
}
interface Questionssummary {
    question_count: string;
    top_five_questions: IQuestion[];
}

export interface IPhoto {
    url: string,
    display_order: string,
    id: string,
    lat?: string,
    lng?: string,
    status_code: string,
    caption?: string,
}

export interface IQuestion {
    answer: string;
    answered_at: string;
    answered_by_user_average_rating: string;
    answered_by_user_first_name: string;
    answered_by_user_handle: string;
    answered_by_user_id: string;
    answered_by_user_last_name: string;
    answered_by_user_points_total: string;
    answered_by_user_profile_picture: string;
    answered_by_user_profile_picture_full_url: string;
    asked_by_user_id: string;
    created_at: string;
    created_by_user_average_rating?: any;
    created_by_user_first_name: string;
    created_by_user_handle: string;
    created_by_user_last_name: string;
    created_by_user_points_total: string;
    created_by_user_profile_picture?: any;
    created_by_user_profile_picture_full_url: string;
    question: string;
    status_code: string;
}
interface Reviewssummary {
    cleanliness: string;
    lighting: string;
    lounge: string;
    maneuverability: string;
    parking: string;
    professionalism: string;
    restrooms: string;
    safety: string;
    security: string;
    times: string;
    count: number;
    overall: string;
    all_time: string;
    last_30_days: string;
    up_to_30_days_ago: string;
    trend: string;
}
interface Media {
    files: any[];
    photos: any[];
    videos: any[];
}
interface Details {
    backing: Backing;
    cb_channel: Backing;
    check_in_location: Checkinlocation;
    current_conditions_code: Checkinlocation;
    current_conditions: Checkinlocation;
    driver_lounge: Backing;
    free_wifi: Backing;
    guns_allowed: Backing;
    heavy_hauler_friendly: Backing;
    kids_allowed: Backing;
    languages: Checkinlocation;
    local_directions: Checkinlocation;
    overnight_parking: Backing;
    overnight_sleeping: Backing;
    pets_allowed: Backing;
    procedure_instructions: Checkinlocation;
    required_internal_temp: Backing;
    restrooms: Backing;
    smoke_free: Backing;
    standard_clearances: Backing;
    twic_required: Backing;
    vending_machine: Backing;
    yard_speed_limit: Backing;
    liftgate_only: Backing;
    load_unload_equipment: Loadunloadequipment;
    load_verification: Backing;
    load_unload_location: Loadunloadlocation;
    ppe_covid: Backing;
    ppe_face_shield: Backing;
    ppe_glasses: Backing;
    ppe_gloves: Backing;
    ppe_hard_hat: Backing;
    ppe_nomex_coveralls: Backing;
    ppe_respirator: Backing;
    ppe_shoes_ankle_cover: Backing;
    ppe_closed_toe_shoes: Backing;
    ppe_shoes_steel_toe: Backing;
    ppe_vest: Backing;
    ppe_vest_high_vis: Backing;
    ppe_vest_reflective: Backing;
    ppe_other: Backing;
    safety_equipment_chocks: Backing;
    safety_equipment_dock_barrier: Backing;
    safety_equipment_dock_leveller: Backing;
    safety_equipment_indicator_lights: Backing;
    safety_equipment_jack_stands: Backing;
    safety_equipment_vehicle_restraints: Backing;
    safety_equipment_other: Backing;
    security_checkpoints: Checkinlocation;
    security_threats: Checkinlocation;
    load_time: Backing;
    unload_time: Backing;
    wait_time: Backing;
    appointment_needed: Appointmentneeded;
    best_arrival_time: Checkinlocation;
    late_wait_time: Backing;
    pays_detention: Backing;
    has_lumper_service: Haslumperservice;
    washout_required: Washoutrequired;
}
interface Washoutrequired {
    value: string;
    comment: string;
    cost: string;
    before_after: string;
    inside_outside: string;
    dr_comment_count: number;
}
interface Haslumperservice {
    value: string;
    comment: string;
    cost: string;
    per: string;
    dr_comment_count: number;
}
interface Appointmentneeded {
    value: string;
    comment: string;
    system_code: string;
    system_url: string;
    dr_comment_count: number;
}
interface Loadunloadlocation {
    indoor: string;
    outdoor: string;
    parking_lot: string;
    dr_comment_count: number;
}
interface Loadunloadequipment {
    value: string;
    crane: string;
    crane_limit: string;
    crane_limit_metric: string;
    forklift: string;
    forklift_limit: string;
    forklift_limit_metric: string;
    other: string;
    other_limit: string;
    other_limit_metric: string;
    dr_comment_count: number;
}
interface Checkinlocation {
    value: string;
    dr_comment_count: number;
}
interface Backing {
    value: string;
    comment: string;
    dr_comment_count: number;
}
interface D411id {
    id: string;
    insert_sequence: string;
    d411_id: string;
    location_type_code: string;
    customer_company_id: string;
    name: string;
    company_name: string;
    approved_company_name_id: string;
    address_id: string;
    address_shipper_uses: string;
    lat: string;
    lng: string;
    geohash: string;
    original_lat: string;
    original_lng: string;
    original_geohash: string;
    d411_facility_map: string;
    manager_name: string;
    contact: string;
    contacts_json: string;
    hours: string;
    hours_json: string;
    time_zone_code: string;
    driver_rating: string;
    driver_rating_last_30_days: string;
    driver_rating_up_to_30_days_ago: string;
    d411_rating: string;
    subscription_type_code: string;
    subscription_expires_at: string;
    subscription_auto_renews: string;
    source_code: string;
    status_code: string;
    views: string;
    notes: string;
    internal_notes: string;
    created_at: string;
    created_by_user_id: string;
    updated_at: string;
    updated_by_user_id: string;
    location_type_name: string;
    customer_company_name: string;
    customer_company_address1: string;
    customer_company_city: string;
    customer_company_state_province_code: string;
    customer_company_postal_code: string;
    customer_company_country_code: string;
    customer_company_main_phone: string;
    approved_company_name_name: string;
    approved_company_name_status_code: string;
    company_name_to_use: string;
    address_address1: string;
    address_address2?: any;
    address_city: string;
    address_state_province_code: string;
    address_postal_code: string;
    address_country_code: string;
    address_full_address: string;
    address_lat: string;
    address_lng: string;
    address_state_province_name: string;
    address_state_province_abbreviation: string;
    address_country_name: string;
    address_country_abbreviation: string;
    address_country_three_letter_abbreviation: string;
    address_country_calling_code: string;
    time_zone_name: string;
    time_zone_is_default: string;
    time_zone_current_time: string;
    time_zone_now: string;
    created_by_user_first_name: string;
    created_by_user_last_name: string;
    created_by_user_handle: string;
    created_by_user_points_total: string;
    created_by_user_average_rating: string;
    created_by_user_profile_picture: string;
    created_by_user_profile_picture_full_url: string;
    updated_by_user_first_name: string;
    updated_by_user_last_name: string;
    updated_by_user_handle: string;
    updated_by_user_points_total: string;
    updated_by_user_average_rating?: any;
    updated_by_user_profile_picture?: any;
    updated_by_user_profile_picture_full_url: string;
}

export interface IList {
    id: string
    list_type_code: 'personal' | 'company'
    name: string
    cover_color_code: string
    cover_color_custom_value: string
    cover_icon_code: string
    cover_image_file_name: string
    cover_image_url: string
    created_at: string
    created_by_user_id: string
    created_by_user_handle: string
    created_by_user_first_name: string
    created_by_user_last_name: string
    created_by_user_city: string
    created_by_user_region: string
    created_by_user_profile_picture: string
    updated_at: string
    updated_by_user_id: string
    d411_id_count: number
    list_d411_ids?: ListD411Id[]
}

export interface ListD411Id {
    id: string
    insert_sequence: number
    d411_id_id: string
    notes: string
    d411_id_d411_id: string
    d411_id_location_type_code: string
    d411_id_name: string
    d411_id_company_name: string
    d411_id_lat: number
    d411_id_lng: number
    d411_id_time_zone_code: string
    d411_id_driver_rating: number
    d411_id_driver_rating_last_30_days: number
    d411_id_driver_rating_up_to_30_days_ago: number
    created_at: string
    created_by_user_id: string
    created_by_user_handle: string
    created_by_user_first_name: string
    created_by_user_last_name: string
    created_by_user_city: string
    created_by_user_region: string
    created_by_user_profile_picture: string
}


export interface IDock {
    id: string;
    list_id: string;
    d411_id_id: string;
    notes: string;
    d411_id_d411_id: string;
    d411_id_location_type_code: string;
    d411_id_name: string;
    d411_id_company_name: string;
    d411_id_city: string;
    d411_id_state_province: string;
    d411_id_lat: string;
    d411_id_lng: string;
    d411_id_time_zone_code: string;
    d411_id_driver_rating: string;
    d411_id_driver_rating_last_30_days: string;
    d411_id_driver_rating_up_to_30_days_ago: string;
    trend: string;
    created_by_user_first_name: string;
    created_by_user_last_name: string;
    created_by_user_handle: string;
    created_by_user_points_total: string;
    created_by_user_average_rating?: any;
    created_by_user_profile_picture?: any;
    created_by_user_profile_picture_full_url: string;
}