import type { AxiosResponse } from 'axios';
import http from './http-common';

export class AnonymousTokenService {
    public static async getAnonymousToken(params?: App.AnonymousUserCreateParams): Promise<{ token: string }> {
        if (!window.location.href.includes('welcome')) {
            const response = await http.post('/anonymous_users', params);
            return { token: response.headers['x-authorization'] };
        }
    }
}
