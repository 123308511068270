import { writable } from 'svelte/store';
import type { Writable } from 'svelte/store';
import { AnonymousTokenService } from '../lib/services/anonymous_token.service';
import httpCommon from '../lib/services/http-common';

function createUserJWTStore(): Writable<'LOADING' | { token: string | null }> {
    const store = writable<'LOADING' | { token: string | null }>('LOADING');

    if (typeof window !== 'undefined') {
        const saved = localStorage.getItem('uat');
        if (!saved || !saved.includes('token')) {
            import('../lib/services/http-common').then(() => {
                AnonymousTokenService.getAnonymousToken().then((data: { token: string }) => {
                    if (data) {
                        store.set({ token: data.token });
                        if (typeof httpCommon !== 'undefined') {
                            httpCommon.defaults.headers.common.Authorization = data.token;
                        }
                    }
                });
            });
        } else {
            const value = JSON.parse(saved);
            import('../lib/services/http-common').then(() => {
                if (httpCommon && typeof httpCommon !== 'undefined') {
                    httpCommon.defaults.headers.common.Authorization = value.token;
                }
            });
            store.set(value as { token: string });
        }
        store.subscribe((value) => {
            if (value !== 'LOADING') {
                localStorage.setItem('uat', JSON.stringify(value));
            }
        });
    }

    return store;
}

const userJWT = createUserJWTStore();
export default userJWT;
