import { toast } from '@zerodevx/svelte-toast';

const base: string = `https://cdn.dock411.com/0000`;
const customerBase: string = `${base}/resources/customer-logos`;
const talkingBase: string = `${base}/resources/friends-logos`;
const techPartnersBase: string = `${base}/partners`;
const sponsorsBase: string = 'https://cdn.dock411.com/0000';

export const X_API_PID: string = import.meta.env.VITE_X_API_PID;
export const X_API_PKEY: string = import.meta.env.VITE_X_API_PKEY;
export const X_API_ID: string = import.meta.env.VITE_X_API_ID;
export const X_API_KEY: string = import.meta.env.VITE_X_API_KEY;
export const TOKEN: string = import.meta.env.VITE_AUTH;

export const X_API_KEY_C: string = import.meta.env.VITE_X_API_KEY_C;
export const X_API_KEY_P: string = import.meta.env.VITE_X_API_KEY_P;
export const BASE_URL: string = import.meta.env.VITE_API_HOSTNAME; //http
export const BASE_URL_SECURE: string = import.meta.env.VITE_API_HOSTNAME_SECURE; //https

export const ReviewItems: App.ReviewItem[] = [
    {
        title: 'Cleanliness',
        question: 'Complete mess or actually neat and orderly?',
        name: 'cleanliness',
        taglines: [
            'Facility was a complete mess!',
            'The place needed organized and cleaned',
            'I\'ve seen dirtier places',
            'Well organized and clean',
            'Mr. Clean would approve!'
        ]
    },
    {
        title: 'Lighting',
        name: 'lighting',
        question: 'Bright as can be or had to use my high beams?',
        taglines: [
            'Dark side of the moon',
            'I think they used candles',
            'I could see ok',
            'Facility is well lit',
            'Thought it was daylight out'
        ]
    },
    {
        title: 'Lounge',
        name: 'lounge',
        question: 'Was there some place to relax? If so, how was it?',
        taglines: [
            'No lounge',
            'Chair for me to sit down',
            'Comfortable',
            'Spacious and clean',
            'Feels like VIP'
        ]
    },
    {
        title: 'Maneuverability',
        name: 'maneuverability',
        question: 'How was the maneuverability? Was it a tight squeeze or ya have plenty of room?',
        taglines: [
            'Barely got my truck in',
            'Tight fit',
            'I made my way thru',
            'Plenty of room',
            'Big enough for a freighter!'
        ]
    },
    {
        title: 'Parking',
        name: 'parking',
        question: 'Could you park at or near their facility?',
        taglines: [
            'None, zilch, nada',
            'Might be a spot in a few miles',
            'Eventually found a spot',
            'More than enough parking',
            'Actually excited about parking'
        ]
    },
    {
        title: 'Professionalism',
        name: 'professionalism',
        question: 'Were you treated like a professional? Was the staff helpful?',
        taglines: [
            'Treated me like dirt',
            'Could have been nicer to me',
            'They did their job',
            'They actually smiled',
            'Left there feeling like y\'all BFF\'s'
        ]
    },
    {
        title: 'Restrooms',
        name: 'restrooms',
        question: 'Were the restrooms satisfactory?',
        taglines: [
            'What restrooms?!',
            'Held it til the next stop',
            'Eh',
            'Clean and well-stocked',
            'Like a 5-star hotel'
        ]
    },
    {
        title: 'Safety',
        name: 'safety',
        question: 'Did the location have safety equipment or requirements?',
        taglines: [
            'Call OSHA immediately',
            'Little effort to be safe',
            'Nothing stood out as unsafe',
            'Staff was safety conscious',
            'Safety was #1 concern'
        ]
    },
    {
        title: 'Security',
        name: 'security',
        question: 'Did you feel secure in the yard and the surrounding area?',
        taglines: [
            'Scared for my life',
            'Sketchy neighborhood',
            'Somewhat secure',
            'Guards and fences',
            'Might as well be Fort Knox'
        ]
    },
    {
        title: 'Times/Timeliness',
        name: 'times',
        question: 'Was it a quick in and out or did they make ya wait all day?',
        taglines: [
            'Waited FOREVER!',
            'Bring a lunch!',
            'I\'ve waited longer at other places',
            'In and out in a timely manner',
            ' Like a NASCAR pitstop '
        ]
    }
];

export const userHeaderLinks = [
    {
        href: '/account',
        text: 'Account'
    },
    {
        href: '/',
        text: 'Dashboard'
    }
];

export const CUSTOMER_LOGOS: App.MinimumAssociatedLogo[] = [
    {
        title: 'Coca-Cola',
        img: {
            src: `${customerBase}/coca-cola-logo-gray.png`,
            alt: 'Coca-Cola Logo'
        },
        className: 'coca-cola'
    },
    {
        title: 'GAF',
        img: {
            src: `${customerBase}/gaf-logo-gray.png`,
            alt: 'GAF Logo'
        },
        className: 'gaf'
    },
    {
        title: 'Interbake Foods',
        img: {
            src: `${customerBase}/interbake-logo-gray.png`,
            alt: 'Interbake Foods Logo'
        },
        className: 'interbake'
    },
    {
        title: 'Procter & Gamble',
        img: {
            src: `${customerBase}/pg-logo-gray.png`,
            alt: 'Procter & Gamble Logo'
        },
        className: 'pg'
    },
    {
        title: 'Sage Glass',
        img: {
            src: `${customerBase}/sage-glass-logo-gray.png`,
            alt: 'Sage Glass Logo'
        },
        className: 'sage-glass'
    },
    {
        title: 'Smith Foods',
        img: {
            src: `${customerBase}/smith-foods-logo-gray.png`,
            alt: 'Smith Foods Logo'
        },
        className: 'smith-foods'
    }
];

export const TECH_PARTNERS_LOGOS: App.AssociatedLogo[] = [
    {
        title: 'DAT',
        img: {
            src: `${techPartnersBase}/dat/logo-dat-partner.png`,
            alt: 'DAT Logo'
        },
        className: 'dat',
        href: 'https://dat.com'
    },
    {
        title: 'FuelMe',
        img: {
            src: `${techPartnersBase}/fuelme/logo-fuelme-partner.png`,
            alt: 'FuelMe Logo'
        },
        className: 'fuelme',
        href: 'https://fuel.me'
    },
    {
        title: 'FreightTracer',
        img: {
            src: `${techPartnersBase}/freighttracer/logo-freighttracer-partner.png`,
            alt: 'FreightTracer Logo'
        },
        className: 'freighttracer',
        href: 'https://freighttracer.com'
    },
    {
        title: 'Full Circle TMS',
        img: {
            src: `${techPartnersBase}/full_circle_tms/logo-full_circle_tms-partner.png`,
            alt: 'Full Circle TMS Logo'
        },
        className: 'fullcircle',
        href: 'https://fullcircletms.com'
    },
    {
        title: 'Konexial',
        img: {
            src: `${techPartnersBase}/konexial/logo-konexial-partner.png`,
            alt: 'Konexial Logo'
        },
        className: 'konexial',
        href: 'https://konexial.com'
    },
    {
        title: 'Open Dock',
        img: {
            src: `${techPartnersBase}/opendock/logo-opendock-partner.png`,
            alt: 'Open Dock Logo'
        },
        className: 'opendock',
        href: 'https://opendock.com'
    },
    {
        title: 'Trucker Tools',
        img: {
            src: `${techPartnersBase}/truckertools/logo-truckertools-partner.png`,
            alt: 'Trucker Tools Logo'
        },
        className: 'truckertools',
        href: 'https://truckertools.com'
    },
    {
        title: 'Truck Stop',
        img: {
            src: `${techPartnersBase}/truckstop/logo-truckstop-partner.png`,
            alt: 'Truck Stop Logo'
        },
        className: 'truckstop',
        href: 'https://truckstop.com'
    },
    {
        title: 'True Load Time',
        img: {
            src: `${techPartnersBase}/trueloadtime/logo-trueloadtime-partner.png`,
            alt: 'True Load Time Logo'
        },
        className: 'trueloadtime',
        href: 'https://trueloadtime.com'
    }
];

export const TALKING_LOGOS: App.AssociatedLogo[] = [
    {
        title: 'Radio Nemo',
        img: {
            alt: 'Radio Nemo Logo',
            src: `${talkingBase}/logo-radio-nemo-rev.png`
        },
        href: 'https://www.radionemo.com/Home/tabid/56/Default.aspx'
    },
    {
        title: 'Overdrive',
        img: {
            alt: 'Overdrive Logo',
            src: `${talkingBase}/logo-overdrive-rev.png`
        },
        href: 'http://www.overdriveonline.com/can-i-get-the-411/'
    },
    {
        title: 'Trucking Industry News',
        img: {
            src: `${talkingBase}/logo-trucking-industry-news-rev.png`,
            alt: 'Trucking Industry News Logo'
        },
        href: 'http://truckingindustry.news/1010/dock411-and-women-in-trucking-announce-app-to-ease-dock-planning-pains.html?lipi=urn%3Ali%3Apage%3Ad_flagship3_company%3BxlvhbLRzSkeUtSK54NR3zA%3D%3D'
    },
    {
        title: 'Expediters Online',
        img: {
            src: `${talkingBase}/logo-expediters-online-rev.png`,
            alt: 'Expediters Online Logo'
        },
        href: 'https://www.expeditersonline.com/trucking-blogs/four-wheeling/help-yourself-and-others-at-the-same-time-8973.htm?lipi=urn%3Ali%3Apage%3Ad_flagship3_company%3BxlvhbLRzSkeUtSK54NR3zA%3D%3D'
    },
    {
        title: 'Freight Waves',
        img: {
            src: `${talkingBase}/logo-freight-waves-rev.png`,
            alt: 'Freight Waves Logo'
        },
        href: 'https://www.freightwaves.com/news/trucking/innovation-key-to-making-the-freight-tech-100'
    },
    {
        title: 'National Trucking Mag',
        img: {
            src: `${talkingBase}/logo-national-truckin-mag-rev.png`,
            alt: 'National Trucking Mag Logo'
        },
        href: 'https://nationaltruckinmagazine.com/'
    }
];

export const SPONSOR_LOGOS: App.AssociatedLogo[] = [
    {
        title: 'Women In Trucking',
        img: {
            alt: 'Women In Trucking Logo',
            src: `${sponsorsBase}/partners/wit/logo-wit-158-gray.png`
        },
        href: 'http://www.womenintrucking.org',
        className: 'wit'
    },
    {
        title: 'American Truckers Rights Group',
        img: {
            alt: 'American Truckers Rights Group Logo',
            src: `${sponsorsBase}/friends/atrg/logo-atrg-110-gray.png`
        },
        href: 'https://www.facebook.com/American-Truckers-Rights-Group-1435646563168103',
        className: 'atrg'
    },
    {
        title: 'For Truckers By Truckers',
        img: {
            src: `${sponsorsBase}/friends/ftbt/logo-ftbt-110-gray.png`,
            alt: 'For Truckers By Truckers Logo'
        },
        href: 'https://www.facebook.com/ForTruckersbyTruckers/',
        className: 'ftbt'
    },
    {
        title: 'Missing Truck Drivers Alert Network',
        img: {
            src: `${sponsorsBase}/friends/mtdan/logo-mtdan-110-gray.png`,
            alt: 'Missing Truck Drivers Alert Network Logo'
        },
        href: 'https://www.facebook.com/Missing.Truck.Driver',
        className: 'mtdan'
    },
    {
        title: 'Ask a Veteran Driver',
        img: {
            src: `${sponsorsBase}/friends/avd/logo-avd-110-gray.png`,
            alt: 'Ask a Veteran Driver Logo'
        },
        href: 'https://www.facebook.com/groups/Askaveterandriver',
        className: 'avd'
    },
    {
        title: 'Xtreme Trucking Information',
        img: {
            src: `${sponsorsBase}/friends/xti/logo-xti-110-gray.png`,
            alt: 'Xtreme Trucking Information Logo'
        },
        href: 'https://www.facebook.com/groups/506006239596822',
        className: 'xti'
    },
    {
        title: 'Genesis Automation',
        img: {
            src: `${sponsorsBase}/sponsors/genesis_automation/logo-genesis_automation-medium.png`,
            alt: 'Genesis Automation Logo'
        },
        href: 'https://www.genesisautomation.com/',
        className: 'genesis'
    },
    {
        title: 'Radio Nemo',
        img: {
            src: `${sponsorsBase}/sponsors/radio_nemo/logo-radio_nemo-medium.png`,
            alt: 'Radio Nemo Ad'
        },
        href: 'http://www.radionemo.com/',
        className: 'sponsor-nemo'
    },
    {
        title: 'US Compliance Services - AMERICA\'S LEADING USDOT & FMCSA COMPLIANCE AGENCY',
        img: {
            src: `${sponsorsBase}/friends/us_compliance_services/logo-us_compliance_services-medium.png`,
            alt: 'US Compliance Services - AMERICA\'S LEADING USDOT & FMCSA COMPLIANCE AGENCY'
        },
        href: 'https://www.uscomplianceservices.org/',
        className: 'us-comp'
    }
];

export const TEST_HOURS_v4: App.HoursJSONV4 = {
    'version': 4,
    'starting_day_of_week': 0,
    'schedules': {
        '1hd875hd': {
            'schedule_type_code': 'regular_hours',
            'name': 'Normal',
            'days_of_week': {
                '0': { 'events': [{ 'start': '00:15', 'end': '01:45', 'event_type_code': 'open' }] },
                '1': { 'events': [{ 'start': '09:00', 'end': '13:00', 'event_type_code': 'open' }] },
                '2': { 'events': [{ 'start': '08:00', 'end': '12:00', 'event_type_code': 'open' }] },
                '3': { 'events': [{ 'start': '07:00', 'end': '18:00', 'event_type_code': 'open' }] },
                '4': { 'events': [{ 'start': '15:00', 'end': '19:00', 'event_type_code': 'open' }] },
                '5': { 'events': [{ 'start': '18:00', 'end': '23:00', 'event_type_code': 'open' }] },
                '6': { 'events': [{ 'start': '01:00', 'end': '03:15', 'event_type_code': 'open' }] }
            }
        },
        '1hd875hdf': {
            'schedule_type_code': 'holidays',
            'name': 'Holidays',
            'days_of_week': {
                '0': { 'events': [{ 'start': '00:15', 'end': '21:00', 'event_type_code': 'open' }] },
                '1': { 'events': [{ 'start': '08:00', 'end': '12:00', 'event_type_code': 'open' }, { 'start': '16:00', 'end': '21:00', 'event_type_code': 'open' }] },
                '2': { 'events': [{ 'start': '08:00', 'end': '12:00', 'event_type_code': 'open' }] },
                '3': { 'events': [{ 'start': '07:00', 'end': '18:00', 'event_type_code': 'open' }] },
                '4': { 'events': [{ 'start': '15:00', 'end': '19:00', 'event_type_code': 'open' }] },
                '5': { 'events': [{ 'start': '18:00', 'end': '23:00', 'event_type_code': 'open' }] },
                '6': { 'events': [{ 'start': '01:00', 'end': '03:15', 'event_type_code': 'open' }] }
            }
        }
    }
};

export const LANGUAGES: { name: string; label: string; }[] = [
    {
        name: 'english',
        label: 'English'
    },
    {
        name: 'spanish',
        label: 'Spanish'
    },
    {
        name: 'french',
        label: 'French'
    }
];


export const toastErrorTheme = {
    '--toastBackground': '#F56565',
    '--toastBarBackground': '#C53030',
    '--toastWidth': '100%',
    '--toastContainerZIndex': '100000'
};

export const toastSuccessTheme = {
    '--toastBackground': '#008000',
    '--toastBarBackground': '#44c530',
    '--toastWidth': '100%',
    '--toastContainerZIndex': '100000'
};

export const toastSuccess = (message: string) => {
    toast.push(message, {
        theme: toastSuccessTheme,
        duration: 3000
    });
};

export const toastError = (message: string) => {
    toast.push(message, {
        theme: toastErrorTheme,
        duration: 3000
    });
};