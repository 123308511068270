import { writable } from 'svelte/store';
import { AnonymousTokenService } from '../lib/services/anonymous_token.service';
import httpCommon from '../lib/services/http-common';

function userJWTStore() {
    const jwtToken = writable<{ token: string }>({ token: '' });

    if (typeof window !== 'undefined') {
        const saved = localStorage.getItem('uat');
        if (saved === null || saved === null || !saved.includes('token')) {
            import('../lib/services/http-common').then(() => {
                AnonymousTokenService.getAnonymousToken().then((data: { token: string }) => {
                    if (data) {
                        jwtToken.set({ token: data.token });

                        if (typeof httpCommon !== 'undefined') {
                            httpCommon.defaults.headers.common.Authorization = data.token;
                        }
                    }
                });
            });
        } else {
            const value = JSON.parse(saved);
            import('../lib/services/http-common').then(() => {
                if (httpCommon && typeof httpCommon !== 'undefined') {
                    httpCommon.defaults.headers.common.Authorization = value.token;
                }
            });
            jwtToken.set({ token: value.token });
        }
        jwtToken.subscribe((value) => {
            if (value.token !== '') {
                localStorage.setItem('uat', JSON.stringify(value));
            }
        });
    }
    const clearUserJWT = () => {
        localStorage.removeItem('uat');
    };
    return {
        jwtToken,
        clearUserJWT
    };
}

export const { jwtToken, clearUserJWT } = userJWTStore();
